import React from "react"

const HeaderImage = ({ imageSharp, targetClass }) => {
  const backgroundImages = imageSharp.fluid.srcSet
    .split(",\n")
    .map(e => {
      let [img, size] = e.split(" ")
      size = size.slice(0, size.lastIndexOf("w"))
      return [img, size]
    })
    .reverse()
  const breakStyles = backgroundImages.map(a => {
    const [img, size] = a
    return `@media (max-width: ${size}px) {
        .${targetClass} { background-image: url(${img}); }
      }`
  })
  const imageStyleStrings = breakStyles.join("\n")
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: imageStyleStrings,
      }}
    />
  )
}

export default HeaderImage
