import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Contact from "../components/Contact"
import HeaderImage from "../components/headerimage"

const ContactPage = ({ data }) => {
  const headerImageSharp = data.imageSharp
  return (
    <Layout classExtra="nofooter">
      <HeaderImage
        imageSharp={headerImageSharp}
        targetClass={"contactbackground"}
      />
      <SEO
        title="Contact Us"
        keywords={[`australian`, `jewellery`, `exclusive`]}
        description={`Contact us to create your custom, bespoke engagtement ring, wedding jewellery, wedding ring, present. Gold and ethical gemstones (lab diamond, moissanite, morganite, sapphire)`}
      />
      <div className="blogwrap">
        <div className="contactbackground" />
        <div className="formfloat">
          <Contact />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const MyQuery = graphql`
  {
    imageSharp(fluid: { originalName: { eq: "contactbackground.jpg" } }) {
      id
      fluid {
        srcSet
      }
    }
  }
`
