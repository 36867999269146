import React from "react"
import { navigate } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <section className="contact py-5">
      <div className="contactlimit">
        <div className="contactrow">
          <div className=".col-10.col-sm8.col-md-6.mx-auto">
            <form
              name="contact"
              method="post"
              action="/submitted"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input
                type="hidden"
                name="form-name"
                className="contactform"
                value="contact"
              />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              <p>
                <div className="form-group">
                  <label className="contactform">
                    Your name:
                    <br />
                    <input
                      type="text"
                      name="name"
                      className="contactform"
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </p>
              <p>
                <div className="form-group">
                  <label className="contactform">
                    Your email:
                    <br />
                    <input
                      type="email"
                      name="email"
                      className="contactform"
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </p>
              <p>
                <div className="form-group">
                  <label className="contactform">
                    Message:
                    <br />
                    <textarea
                      name="message"
                      onChange={handleChange}
                      className="contactform"
                      rows="5"
                    />
                  </label>
                </div>
              </p>
              <p>
                <button type="submit" className="btn btn-block mt-5">
                  Submit
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
